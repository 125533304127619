export const SUNDAY = 'SUNDAY'
export const MONDAY = 'MONDAY'
export const TUESDAY = 'TUESDAY'
export const WEDNESDAY = 'WEDNESDAY'
export const THURSDAY = 'THURSDAY'
export const FRIDAY = 'FRIDAY'
export const SATURDAY = 'SATURDAY'

export const QUESTION1 = 'QUESTION1'
export const QUESTION2 = 'QUESTION2'
export const QUESTION3 = 'QUESTION3'
export const QUESTION4 = 'QUESTION4'
export const QUESTION5 = 'QUESTION5'
export const QUESTION6 = 'QUESTION6'